import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "../styles/businessBackofficeStyles.scss";
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Form,
} from "reactstrap";
import {
  getDocumentAlerts,
  getDocumentById,
  getDocumentsAndEmailsByIds,
  getDocumentsByIds,
  getExtractionDropdownOptions,
  getExtractionFields,
  getRelatedDocumentGroup,
  sendDocumentsEmail,
  sendTranslatableEmail,
  updateExtractionField,
} from "../../ApiCalls/mantisBusiness";
import { stringify } from "../../utils/dataExtractionUtils";
import { shortenText } from "../../utils/formatText";
import pdfIcon from "../../Assets/img/icons/pdf.png";
import imageIcon from "../../Assets/img/icons/image.png";
import excelIcon from "../../Assets/img/icons/excel.png";
import gmailIcon from "../../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import { checkMantisBusinessUserPermission } from "../../utils/businessUtils";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function DocumentAnalysisModule(props) {
  //Possible types: alerts, documentDetail, relatedDocuments, brinksMain, brinksOfficeDocuments, tangeloRequirements, tangeloMain, tangeloCaseDocuments, tangeloCasePetitions, tangeloStatus

  const {
    title,
    type,
    mainId,
    selectedRegister,
    selectedRegisters,
    brinksOffice,
    brinksShownFile,
    relatedDocumentsGroupId,
    alerts,
    tangeloCase,
    tangeloShownFile,
    tangeloShownEmail,
    alertsLoading,
    tangeloStatus,
    tangeloCategory,
  } = props;

  //Document details type
  const [loading, setLoading] = useState(true);
  const [document, setDocument] = useState(null);
  const [expandedFields, setExpandedFields] = useState({});
  const [extractionFields, setExtractionFields] = useState({});
  const [extractionDropdownOptions, setExtractionDropdownOptions] = useState(
    {}
  );

  //Related documents type
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [relatedEmails, setRelatedEmails] = useState([]);
  const [shownBrinksFile, setShownBrinksFile] = useState({});
  const [shownBrinksOffice, setShownBrinksOffice] = useState(brinksOffice);
  const [brinksFieldsToShow, setBrinksFieldsToShow] = useState([]);
  const [brinksFieldToUpdate, setBrinksFieldToUpdate] = useState(null);
  const [brinksFieldHovered, setBrinksFieldHovered] = useState(null);
  const [updatingBrinksField, setUpdatingBrinksField] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailLanguage, setEmailLanguage] = useState("es");
  const [sendingEmail, setSendingEmail] = useState(false);

  //Tangelo
  const [shownTangeloFile, setShownTangeloFile] = useState(
    tangeloShownFile || {}
  );
  const [shownTangeloEmail, setShownTangeloEmail] = useState(
    tangeloShownEmail || {}
  );
  const [shownTangeloCase, setShownTangeloCase] = useState(tangeloCase);

  //Modals
  const [showSendDocumentsModal, setShowSendDocumentsModal] = useState(false);
  const [showSendTangeloCaseModal, setShowSendTangeloCaseModal] =
    useState(false);
  const [showUpdateBrinksFieldModal, setShowUpdateBrinksFieldModal] =
    useState(true);

  useEffect(() => {
    if (type === "documentDetail") {
      getFile();
    } else if (type === "relatedDocuments") {
      getRelatedDocuments();
    } else if (type === "brinksOfficeDocuments") {
      getBrinksOfficeDocuments();
    } else if (type === "brinksMain") {
      getBrinksOfficeDocuments();
    } else if (type === "alerts") {
      props.getAlerts(relatedDocumentsGroupId);
    } else if (
      type === "tangeloMain" ||
      type === "tangeloCaseDocuments" ||
      type === "tangeloStatus"
    ) {
      getTangeloCaseDocumentsAndEmails();
    }
  }, []);

  useEffect(() => {
    if (type === "documentDetail") {
      getFile();
    }
  }, [selectedRegister]);

  useEffect(() => {
    setShownBrinksFile(brinksShownFile);
  }, [brinksShownFile]);

  useEffect(() => {
    setShownTangeloFile(tangeloShownFile);
  }, [tangeloShownFile]);

  useEffect(() => {
    setShownTangeloEmail(tangeloShownEmail);
  }, [tangeloShownEmail]);

  const getFile = async () => {
    setLoading(true);
    const response = await getDocumentById(
      mainId,
      props.mantisBusiness._id,
      props.token
    );
    if (response.success) {
      setDocument(response.document);
    } else {
      alert("Error al obtener el documento");
    }
    setLoading(false);
  };

  const getBrinksOfficeDocuments = async () => {
    setLoading(true);
    const response = await getRelatedDocumentGroup(
      props.token,
      brinksOffice.file_group_id,
      props.mantisBusiness._id
    );
    if (response.success) {
      setRelatedDocuments(response.documents);
      setShownBrinksFile(response.documents[0]);
    }
    const fieldsResponse = await getExtractionFields(
      props.token,
      props.mantisBusiness._id
    );
    if (fieldsResponse.success) {
      setExtractionFields(fieldsResponse.fields);
    }
    setLoading(false);
  };

  const getTangeloCaseDocumentsAndEmails = async () => {
    setLoading(true);
    const response = await getRelatedDocumentGroup(
      props.token,
      tangeloCase.file_group_id,
      props.mantisBusiness._id
    );
    if (response.success) {
      setRelatedDocuments(response.documents);
      setRelatedEmails(response.emails);
      if (response.emails.length > 0) {
        setShownTangeloEmail(response.emails[0]);
        setShownTangeloFile({});
      } else if (response.documents.length > 0) {
        setShownTangeloFile(response.documents[0]);
        setShownTangeloEmail({});
      }
    }
    const fieldsResponse = await getExtractionFields(
      props.token,
      props.mantisBusiness._id
    );
    if (fieldsResponse.success) {
      setExtractionFields(fieldsResponse.fields);
    }
    const dropdownOptionsResponse = await getExtractionDropdownOptions(
      props.token,
      props.mantisBusiness._id
    );
    if (dropdownOptionsResponse.success) {
      setExtractionDropdownOptions(dropdownOptionsResponse.options);
    }
    setLoading(false);
  };

  const updateBrinksField = async () => {
    setUpdatingBrinksField(true);
    const response = await updateExtractionField(
      props.token,
      brinksFieldToUpdate.key,
      brinksFieldToUpdate.value,
      brinksOffice.main_key,
      props.mantisBusiness._id
    );
    if (response.success) {
      setUpdatingBrinksField(false);
      setShowUpdateBrinksFieldModal(false);
      setShownBrinksOffice(response.updated_fields);
      props.getAlerts(brinksOffice.file_group_id);
    } else {
      alert("Error al actualizar el campo");
      setUpdatingBrinksField(false);
    }
  };

  const getRelatedDocuments = async () => {
    setLoading(true);
    let documentIds = selectedRegisters.map((register) => register.main_id);
    //remove duplicates
    documentIds = [...new Set(documentIds)];
    const relatedDocumentsResponse = await getDocumentsByIds(
      documentIds,
      props.mantisBusiness._id,
      props.token
    );
    if (relatedDocumentsResponse.success) {
      setRelatedDocuments(relatedDocumentsResponse.documents);
    } else {
      alert("Error al obtener los documentos relacionados");
    }
    setLoading(false);
  };

  const handleSendDocumentsEmail = async () => {
    setSendingEmail(true);
    const response = await sendDocumentsEmail(
      props.token,
      subject,
      message,
      "Oficina Brinks",
      selectedDocuments.map((doc) => doc._id),
      props.mantisBusiness._id,
      emailAddress
    );
    if (response.success) {
      alert("Correo enviado correctamente");
    } else {
      alert("Error al enviar el correo");
    }
    setSendingEmail(false);
    setShowSendDocumentsModal(false);
  };

  const sendTranslatableEmail = async () => {
    setSendingEmail(true);
    const response = await sendTranslatableEmail(
      props.token,
      subject,
      message,
      "Caso Tangelo",
      relatedDocuments.map((doc) => doc._id),
      props.mantisBusiness._id,
      emailAddress,
      emailLanguage
    );
    if (response.success) {
      alert("Correo enviado correctamente");
    } else {
      alert("Error al enviar el correo");
    }
    setSendingEmail(false);
    setShowSendTangeloCaseModal(false);
  };

  return (
    <>
      {type === "documentDetail" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col>
                  <div className="document-analysis-module-fields-container">
                    {Object.keys(selectedRegister).map((key, index) => {
                      if (
                        key !== "main_id" &&
                        key !== "business_id" &&
                        key !== "type"
                      ) {
                        return (
                          <span
                            className="document-analysis-module-field"
                            key={index}
                          >
                            {!selectedRegister[key] && "🟠 "}
                            <strong>{key}</strong>:{" "}
                            {expandedFields[key]
                              ? selectedRegister[key]
                                ? stringify(selectedRegister[key])
                                : "-"
                              : selectedRegister[key]
                              ? shortenText(
                                  stringify(selectedRegister[key]),
                                  50
                                )
                              : "-"}
                            {selectedRegister[key]?.length > 50 && (
                              <>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    setExpandedFields({
                                      ...expandedFields,
                                      [key]: !expandedFields[key],
                                    })
                                  }
                                >
                                  {expandedFields[key]
                                    ? "Ver menos"
                                    : "Ver más"}
                                </span>
                              </>
                            )}
                          </span>
                        );
                      }
                    })}
                  </div>
                </Col>
                {document && (
                  <Col>
                    {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                    <iframe
                      src={document.remoteFile}
                      style={{ width: "100%", height: "100%" }}
                      title="file"
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : type === "relatedDocuments" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">
            Documentos relacionados
          </h3>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              relatedDocuments.map((document, index) => (
                <Col
                  key={index}
                  xs={6}
                  onClick={() => {
                    props.setSelectedRegister(
                      selectedRegisters.find(
                        (register) => register.main_id === document._id
                      )
                    );
                  }}
                >
                  <div
                    className={
                      document._id === selectedRegister.main_id
                        ? "document-analysis-module-active-related-document-container"
                        : "document-analysis-module-related-document-container"
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt="file"
                        className="document-analysis-module-related-document-icon"
                      />
                    </div>
                    <h5 className="document-analysis-module-related-document-name">
                      {document.name}
                    </h5>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      ) : type === "alerts" ? (
        <div div className="document-analysis-module-container-alerts">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row>
            {props.alertsLoading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col>
                  <div className="document-analysis-module-fields-container">
                    {alerts
                      .filter((alert) => alert.type !== "SUBALERT")
                      .map((alert, index) => (
                        <div key={index}>
                          <span className="document-analysis-module-alert">
                            {alert.type === "INFO"
                              ? "🟢 "
                              : alert.type === "ALERT"
                              ? "🔴 "
                              : "-"}{" "}
                            {alert.message}
                          </span>
                        </div>
                      ))}
                    {alerts.filter((alert) => alert.type === "SUBALERT")
                      .length > 0 && (
                      <div
                        style={{
                          border: "1px solid white",
                          height: "1px",
                          width: "100%",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      ></div>
                    )}
                    {alerts
                      .filter((alert) => alert.type === "SUBALERT")
                      .map((alert, index) => (
                        <div key={index}>
                          <span className="document-analysis-module-alert">
                            {"🟡 "}
                            {alert.message}
                          </span>
                        </div>
                      ))}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      ) : type === "tangeloCasePetitions" ? (
        <div div className="document-analysis-module-container-alerts">
          <h3 className="document-analysis-module-title">Resumen</h3>
          <br />
          {/* Categoría del caso */}
          {tangeloCase.categoria && (
            <div className="document-analysis-module-alert">
              Categoría {tangeloCase.categoria}
            </div>
          )}
          <div>
            <span className="document-analysis-module-alert">
              Caso {tangeloCase.main_key}
            </span>
          </div>
          <div>
            <span className="document-analysis-module-alert">
              {tangeloCase.nombre_solicitante}
            </span>
          </div>
          <div>
            <span className="document-analysis-module-alert">
              {tangeloCase.tipo_documento_solicitante || "CC"}{" "}
              {tangeloCase.documento_solicitante}
            </span>
          </div>
          {tangeloCase.peticion_1 &&
            tangeloCase.peticion_1 !== "[]" &&
            tangeloCase.peticion_1 !== "null" && (
              <>
                <hr
                  style={{
                    backgroundColor: "white",
                    height: "1px",
                    border: "none",
                    marginBottom: "10px",
                  }}
                />
                <h3 className="document-analysis-module-title">{title}</h3>
                <br />
                <Row>
                  <Col>
                    <div className="document-analysis-module-fields-container">
                      {tangeloCase.peticion_1 &&
                      tangeloCase.peticion_1 !== "[]" &&
                      tangeloCase.peticion_1 !== "null"
                        ? JSON.parse(
                            tangeloCase.peticion_1.replace(/\n/g, "")
                          ).map((petition, index) => (
                            <div key={index}>
                              <span className="document-analysis-module-alert">
                                - {petition}
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  </Col>
                </Row>
              </>
            )}
        </div>
      ) : type === "tangeloStatus" ? (
        <div div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <br />
          {!loading ? (
            <Row>
              <Col>
                <div className="document-analysis-module-fields-container">
                  {props.changingStatus ? (
                    <Spinner
                      color="white"
                      style={{
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <>
                      <Row>
                        <Col xs={6}>
                          <Form>
                            <FormGroup>
                              <Input
                                type="select"
                                name="status"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  props.updateTangeloCase(
                                    tangeloCase.main_key,
                                    "status",
                                    e.target.value
                                  );
                                }}
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  height: "40px",
                                  justifyContent: "center",
                                }}
                                value={tangeloStatus ? tangeloStatus : "-"}
                              >
                                {extractionDropdownOptions.states.map(
                                  (state) => (
                                    <option
                                      value={state.value}
                                      className="business-extraction-select-option"
                                    >
                                      {state.label}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>

                        <Col xs={6}>
                          <Form>
                            <FormGroup>
                              <Input
                                type="select"
                                name="categoria"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  props.updateTangeloCase(
                                    tangeloCase.main_key,
                                    "categoria",
                                    e.target.value
                                  );
                                }}
                                style={{
                                  textAlign: "center",
                                  fontSize: "0.8rem",
                                  height: "40px",
                                  justifyContent: "center",
                                }}
                                value={
                                  tangeloCategory
                                    ? tangeloCategory
                                    : "SIN CLASIFICAR"
                                }
                              >
                                {extractionDropdownOptions.categories.map(
                                  (state) => (
                                    <option
                                      value={state.value}
                                      className="business-extraction-select-option"
                                    >
                                      {state.label}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col
                          xs={10}
                          style={{
                            margin: "auto",
                            marginTop: "20px",
                          }}
                        >
                          <h4 className="document-analysis-module-alert">
                            Fecha máxima de respuesta:{" "}
                            {new Date(
                              tangeloCase.fecha_respuesta_maxima
                            ).toLocaleDateString("es")}
                          </h4>
                        </Col>
                        <Col
                          xs={10}
                          style={{
                            margin: "auto",
                            marginTop: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowSendTangeloCaseModal(true);
                              setEmailAddress("");
                              setSubject(
                                "Caso " +
                                  tangeloCase.main_key +
                                  " - " +
                                  tangeloCase.nombre_solicitante
                              );
                              setMessage(
                                "Resumen del caso: " + tangeloCase.resumen
                              );
                            }}
                          >
                            <h5
                              style={{
                                color: "white",
                                marginRight: "10px",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "0.8rem",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              Enviar caso
                            </h5>

                            <i
                              className="fas fa-paper-plane"
                              style={{
                                cursor: "pointer",
                                color: "white",
                                marginRight: "10px",
                                fontSize: "20px",
                              }}
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
              <Spinner
                color="white"
                style={{
                  alignSelf: "center",
                }}
              />
            </Col>
          )}
        </div>
      ) : type === "brinksMain" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">{title}</h3>
          <Row
            style={{
              height: "90%",
            }}
          >
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                <Col
                  style={{
                    overflow: "scroll",
                    scrollbarWidth: "none",
                  }}
                  xs={5}
                >
                  <div className="document-analysis-module-fields-container">
                    {Object.keys(shownBrinksOffice).map((key, index) => {
                      let fieldsToShow = [];
                      if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["inspector_main_id"]
                      ) {
                        fieldsToShow = extractionFields.inspectorFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreJAFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["ido_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.idoJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["ido_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.idoJAFields;
                      } else if (
                        shownBrinksFile._id === shownBrinksOffice["doe_main_id"]
                      ) {
                        fieldsToShow = extractionFields.doeFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["codigo_oficina_main_id"]
                      ) {
                        fieldsToShow = extractionFields.codigoOficina;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["provision_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.provisionJNFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["provision_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.provisionJAFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["banco_en_linea_main_id"]
                      ) {
                        fieldsToShow = extractionFields.bancoEnLineaFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["reversa_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.reversaJN;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["reversa_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.reversaJA;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["timbre_sobrante_jn_main_id"]
                      ) {
                        fieldsToShow = extractionFields.timbreSobranteJN;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["timbre_sobrante_ja_main_id"]
                      ) {
                        fieldsToShow = extractionFields.timbreSobranteJA;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["transito_americano_main_id"]
                      ) {
                        fieldsToShow = extractionFields.transitoAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cuadre_americano_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cuadreAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["remanente_americano_main_id"]
                      ) {
                        fieldsToShow =
                          extractionFields.remanenteAmericanoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_codigo_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoCodigoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_cmc_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoCMCFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_eps_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoEPSFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["unificado_clinica_main_id"]
                      ) {
                        fieldsToShow = extractionFields.unificadoClinicaFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["bsafe_main_id"]
                      ) {
                        fieldsToShow = extractionFields.bsafeFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["diario_efectivo_main_id"]
                      ) {
                        fieldsToShow = extractionFields.diarioEfectivoFields;
                      } else if (
                        shownBrinksFile._id ===
                        shownBrinksOffice["cierre_pickup_main_id"]
                      ) {
                        fieldsToShow = extractionFields.cierrePickupFields;
                      } else {
                        fieldsToShow = [];
                      }

                      if (
                        fieldsToShow
                          .map((field) => field.fieldName)
                          .includes(key)
                      ) {
                        let friendlyName = fieldsToShow.find(
                          (field) => field.fieldName === key
                        ).friendlyName;
                        return (
                          <span
                            className="document-analysis-module-field"
                            key={index}
                            onMouseEnter={() => {
                              if (
                                checkMantisBusinessUserPermission(
                                  props.user,
                                  "EDIT_TAKTIK"
                                )
                              ) {
                                setBrinksFieldHovered(key);
                              }
                            }}
                            onMouseLeave={() => {
                              setBrinksFieldHovered(null);
                            }}
                          >
                            {!shownBrinksOffice[key] && "🟠 "}
                            <strong>{friendlyName}</strong>:{" "}
                            {expandedFields[key]
                              ? shownBrinksOffice[key]
                                ? stringify(shownBrinksOffice[key])
                                : "-"
                              : shownBrinksOffice[key]
                              ? shortenText(
                                  stringify(shownBrinksOffice[key]),
                                  50
                                )
                              : "-"}
                            {shownBrinksOffice[key]?.length > 50 && (
                              <>
                                <br />
                                <span
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    setExpandedFields({
                                      ...expandedFields,
                                      [key]: !expandedFields[key],
                                    })
                                  }
                                >
                                  {expandedFields[key]
                                    ? "Ver menos"
                                    : "Ver más"}
                                </span>
                              </>
                            )}
                            {brinksFieldHovered === key && (
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setBrinksFieldToUpdate({
                                    key: key,
                                    value: shownBrinksOffice[key],
                                  });
                                  setShowUpdateBrinksFieldModal(true);
                                }}
                              >
                                <i className="fas fa-edit"></i>
                              </span>
                            )}
                          </span>
                        );
                      }
                    })}
                  </div>
                </Col>
                {shownBrinksFile && (
                  <Col xs={7}>
                    {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                    {shownBrinksFile.remoteFile.includes("pdf") ? (
                      <iframe
                        src={shownBrinksFile.remoteFile}
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                      />
                    ) : shownBrinksFile.remoteFile.includes("png") ||
                      shownBrinksFile.remoteFile.includes("jpg") ||
                      shownBrinksFile.remoteFile.includes("jpeg") ? (
                      <img
                        src={shownBrinksFile.remoteFile}
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                        alt="file"
                      />
                    ) : (
                      <iframe
                        src={
                          "https://view.officeapps.live.com/op/embed.aspx?src=" +
                          shownBrinksFile.remoteFile
                        }
                        style={{ width: "100%", height: "100%" }}
                        title="file"
                      />
                    )}
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : type === "brinksOfficeDocuments" ? (
        <div className="document-analysis-module-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="document-analysis-module-title">
                Documentos relacionados
              </h3>
              <Input
                placeholder="Buscar documento"
                className="document-analysis-module-related-document-checkbox"
                type="checkbox"
                checked={selectedDocuments.length === relatedDocuments.length}
                style={{
                  marginLeft: "10px",
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedDocuments(relatedDocuments);
                  } else {
                    setSelectedDocuments([]);
                  }
                }}
              />
            </div>
            {selectedDocuments.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowSendDocumentsModal(true)}
              >
                <h5
                  style={{
                    color: "white",
                    marginRight: "10px",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "0.8rem",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Enviar {selectedDocuments.length} documento
                  {selectedDocuments.length > 1 ? "s" : ""}
                </h5>

                <i
                  className="fas fa-paper-plane"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                ></i>
              </div>
            )}
          </div>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              relatedDocuments.map((document, index) => (
                <Col
                  key={index}
                  xs={6}
                  onClick={() => {
                    setShownBrinksFile(document);
                    props.setBrinksShownFile(document);
                  }}
                >
                  <div
                    className={
                      document._id === shownBrinksFile._id
                        ? "document-analysis-module-active-related-document-container"
                        : "document-analysis-module-related-document-container"
                    }
                  >
                    <Input
                      className="document-analysis-module-related-document-checkbox"
                      type="checkbox"
                      checked={selectedDocuments
                        ?.map((doc) => doc._id)
                        .includes(document._id)}
                      style={{
                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDocuments([
                            ...selectedDocuments,
                            document,
                          ]);
                        } else {
                          setSelectedDocuments(
                            selectedDocuments.filter(
                              (doc) => doc._id !== document._id
                            )
                          );
                        }
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          document.remoteFile.includes("pdf")
                            ? pdfIcon
                            : document.remoteFile.includes("png") ||
                              document.remoteFile.includes("jpg") ||
                              document.remoteFile.includes("jpeg")
                            ? imageIcon
                            : document.remoteFile.includes("xlsx") ||
                              document.remoteFile.includes("xls")
                            ? excelIcon
                            : ""
                        }
                        alt="file"
                        className="document-analysis-module-related-document-icon"
                      />
                    </div>
                    <h5 className="document-analysis-module-related-document-name">
                      {document.name}
                    </h5>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      ) : type === "tangeloCaseDocuments" ? (
        <div className="document-analysis-module-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="document-analysis-module-title">
                Elementos relacionados
              </h3>
            </div>
          </div>
          <Row>
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                {relatedDocuments.map((document, index) => (
                  <Col
                    key={index}
                    xs={6}
                    onClick={() => {
                      setShownTangeloFile(document);
                      setShownTangeloEmail({});
                      props.setTangeloShownFile(document);
                      props.setTangeloShownEmail({});
                    }}
                  >
                    <div
                      className={
                        document._id === shownTangeloFile._id
                          ? "document-analysis-module-active-related-document-container"
                          : "document-analysis-module-related-document-container"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            document.remoteFile.includes("pdf")
                              ? pdfIcon
                              : document.remoteFile.includes("png") ||
                                document.remoteFile.includes("jpg") ||
                                document.remoteFile.includes("jpeg")
                              ? imageIcon
                              : document.remoteFile.includes("xlsx") ||
                                document.remoteFile.includes("xls")
                              ? excelIcon
                              : ""
                          }
                          alt="file"
                          className="document-analysis-module-related-document-icon"
                        />
                      </div>
                      <h5 className="document-analysis-module-related-document-name">
                        {document.name}
                      </h5>
                    </div>
                  </Col>
                ))}
                {relatedEmails.map((email, index) => (
                  <Col
                    key={index}
                    xs={6}
                    onClick={() => {
                      setShownTangeloEmail(email);
                      setShownTangeloFile({});
                      props.setTangeloShownEmail(email);
                      props.setTangeloShownFile({});
                    }}
                  >
                    <div
                      className={
                        email._id === shownTangeloEmail._id
                          ? "document-analysis-module-active-related-document-container"
                          : "document-analysis-module-related-document-container"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={gmailIcon}
                          alt="file"
                          className="document-analysis-module-related-document-icon"
                          style={{
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                      <h5 className="document-analysis-module-related-document-name">
                        {email.subject}
                      </h5>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </div>
      ) : type === "tangeloMain" ? (
        <div className="document-analysis-module-container">
          <h3 className="document-analysis-module-title">
            {shownTangeloCase.categoria}: {title}
          </h3>
          <Row
            style={{
              height: "90%",
            }}
          >
            {loading ? (
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            ) : (
              <>
                {Object.keys(shownTangeloFile).length > 0 && (
                  <>
                    <Col
                      style={{
                        overflow: "scroll",
                        scrollbarWidth: "none",
                      }}
                      xs={5}
                    >
                      <div className="document-analysis-module-fields-container">
                        {Object.keys(shownTangeloCase).map((key, index) => {
                          let fieldsToShow = [];
                          if (
                            shownTangeloFile._id ===
                            shownTangeloCase["pqr_main_id"]
                          ) {
                            fieldsToShow = extractionFields.pqrsFields;
                          }

                          if (
                            fieldsToShow
                              .map((field) => field.fieldName)
                              .includes(key)
                          ) {
                            let friendlyName = fieldsToShow.find(
                              (field) => field.fieldName === key
                            ).friendlyName;
                            return (
                              <span
                                className="document-analysis-module-field"
                                key={index}
                              >
                                {!shownTangeloCase[key] && "🟠 "}
                                <strong>{friendlyName}</strong>:{" "}
                                {expandedFields[key]
                                  ? shownTangeloCase[key]
                                    ? stringify(shownTangeloCase[key], false)
                                    : "-"
                                  : shownTangeloCase[key]
                                  ? shortenText(
                                      stringify(shownTangeloCase[key], false),
                                      50
                                    )
                                  : "-"}
                                {shownTangeloCase[key]?.length > 50 && (
                                  <>
                                    <br />
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() =>
                                        setExpandedFields({
                                          ...expandedFields,
                                          [key]: !expandedFields[key],
                                        })
                                      }
                                    >
                                      {expandedFields[key]
                                        ? "Ver menos"
                                        : "Ver más"}
                                    </span>
                                  </>
                                )}
                              </span>
                            );
                          }
                        })}
                      </div>
                    </Col>
                    <Col xs={7}>
                      {/* <img
                  src={document.remoteFile}
                  alt="file"
                  className="document-analysis-module-file"
                  /> */}
                      {shownTangeloFile.remoteFile.includes("pdf") ? (
                        <iframe
                          src={shownTangeloFile.remoteFile}
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                        />
                      ) : shownTangeloFile.remoteFile.includes("png") ||
                        shownTangeloFile.remoteFile.includes("jpg") ||
                        shownTangeloFile.remoteFile.includes("jpeg") ? (
                        <img
                          src={shownTangeloFile.remoteFile}
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                          alt="file"
                        />
                      ) : (
                        <iframe
                          src={
                            "https://view.officeapps.live.com/op/embed.aspx?src=" +
                            shownTangeloFile.remoteFile
                          }
                          style={{ width: "100%", height: "100%" }}
                          title="file"
                        />
                      )}
                    </Col>
                  </>
                )}
                {Object.keys(shownTangeloEmail).length > 0 && (
                  <Col xs={12}>
                    <div className="document-analysis-module-fields-container">
                      <span className="document-analysis-module-field">
                        <strong>Correo electrónico</strong>:{" "}
                        {shownTangeloEmail.senderEmail}
                      </span>
                      <span className="document-analysis-module-field">
                        <strong>Asunto</strong>: {shownTangeloEmail.subject}
                      </span>
                      <span
                        className="document-analysis-module-field"
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <strong>Mensaje</strong>: {shownTangeloEmail.body}
                      </span>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : (
        <></>
      )}
      {showUpdateBrinksFieldModal && brinksFieldToUpdate && (
        <Modal
          isOpen={showUpdateBrinksFieldModal}
          toggle={() => {
            if (!updatingBrinksField) setShowUpdateBrinksFieldModal(false);
          }}
        >
          <ModalHeader>
            Actualizar{" "}
            {
              Object.values(extractionFields)
                .find((field) =>
                  field
                    .map((f) => f.fieldName)
                    .includes(brinksFieldToUpdate.key)
                )
                .find((f) => f.fieldName === brinksFieldToUpdate.key)
                .friendlyName
            }
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={brinksFieldToUpdate.value}
                onChange={(e) =>
                  setBrinksFieldToUpdate({
                    key: brinksFieldToUpdate.key,
                    value: e.target.value,
                  })
                }
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (!updatingBrinksField) updateBrinksField();
              }}
            >
              {updatingBrinksField ? (
                <Spinner color="white" size={"sm"} />
              ) : (
                "Actualizar"
              )}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                if (!updatingBrinksField) setShowUpdateBrinksFieldModal(false);
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showSendDocumentsModal && (
        <Modal
          isOpen={showSendDocumentsModal}
          toggle={() => {
            if (!sendingEmail) {
              setShowSendDocumentsModal(false);
            }
          }}
        >
          <ModalHeader>
            Enviar {selectedDocuments.length}{" "}
            {selectedDocuments.length > 1 ? "documentos" : "documento"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="fieldValue">Correo electrónico</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Asunto</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Mensaje</Label>
              <Input
                type="textarea"
                name="fieldValue"
                id="fieldValue"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <ul>
              {selectedDocuments.map((document, index) => (
                <li key={index}>{document.name}</li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (!sendingEmail) {
                  handleSendDocumentsEmail();
                }
              }}
            >
              {sendingEmail ? <Spinner color="white" size={"sm"} /> : "Enviar"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                if (!sendingEmail) {
                  setShowSendDocumentsModal(false);
                }
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showSendTangeloCaseModal && (
        <Modal
          isOpen={showSendTangeloCaseModal}
          toggle={() => {
            if (!sendingEmail) {
              setShowSendTangeloCaseModal(false);
            }
          }}
        >
          <ModalHeader>
            Enviar {relatedDocuments.length}{" "}
            {relatedDocuments.length > 1 ? "documentos" : "documento"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="fieldValue">Correo electrónico</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Asunto</Label>
              <Input
                type="text"
                name="fieldValue"
                id="fieldValue"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fieldValue">Mensaje</Label>
              <Input
                type="textarea"
                name="fieldValue"
                id="fieldValue"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="english"
                placeholder="Inglés"
                className="document-analysis-module-related-document-checkbox"
                type="checkbox"
                checked={emailLanguage === "en"}
                style={{
                  marginRight: "10px",
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEmailLanguage("en");
                  } else {
                    setEmailLanguage("es");
                  }
                }}
              />
              <Label for="english">Inglés</Label>
            </FormGroup>

            <ul>
              {relatedDocuments.map((document, index) => (
                <li key={index}>{document.name}</li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (!sendingEmail) {
                  sendTranslatableEmail();
                }
              }}
            >
              {sendingEmail ? <Spinner color="white" size={"sm"} /> : "Enviar"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => {
                if (!sendingEmail) {
                  setShowSendTangeloCaseModal(false);
                }
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default connect(select)(DocumentAnalysisModule);
