import React, { useState } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "./styles/businessBackofficeStyles.scss";

import BusinessUsers from "./BusinessUsers";
import Employees from "./Employees";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function UsersSection(props) {
  const [tab, setTab] = useState("SYSTEM_USERS");
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        <div
          className={
            tab === "SYSTEM_USERS" ? "active-business-tab" : "business-tab"
          }
          onClick={() => setTab("SYSTEM_USERS")}
        >
          <h4 className="business-viewpanel-tab-title">Usuarios del sistema</h4>
        </div>
        <div
          className={
            tab === "EMPLOYEES" ? "active-business-tab" : "business-tab"
          }
          onClick={() => setTab("EMPLOYEES")}
        >
          <h4 className="business-viewpanel-tab-title">Empleados</h4>
        </div>
      </div>

      {tab === "SYSTEM_USERS" && <BusinessUsers {...props} />}
      {tab === "EMPLOYEES" && <Employees {...props} />}
    </div>
  );
}

export default connect(select)(UsersSection);
