import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./styles/businessBackofficeStyles.scss";
import { brand } from "../config";
import mantisLogo from "../Assets/img/mantisBlackLogo.png";
import mantisBlackLogo from "../Assets/img/icons/b2b/mantisBlack.png";
import mantisWhiteLogo from "../Assets/img/icons/b2b/mantisWhite.png";
import sandyLogo from "../Assets/img/sandyIsotype.png";
import mantisWhiteCompleteLogo from "../Assets/img/mantisWhiteLogo2.png";
import sandyCompleteLogo from "../Assets/img/sandyLogo.png";
import bellWhite from "../Assets/img/icons/b2b/bellWhite.png";
import bellBlack from "../Assets/img/icons/b2b/bellBlack.png";
import folderBlack from "../Assets/img/icons/b2b/folderBlack.png";
import folderWhite from "../Assets/img/icons/b2b/folderWhite.png";
import dashboardWhite from "../Assets/img/icons/b2b/dashboardWhite.png";
import dashboardBlack from "../Assets/img/icons/b2b/dashboardBlack.png";
import integrationsWhite from "../Assets/img/icons/b2b/integrationsWhite.png";
import integrationsBlack from "../Assets/img/icons/b2b/integrationsBlack.png";
import usersBlack from "../Assets/img/icons/b2b/usersBlack.png";
import usersWhite from "../Assets/img/icons/b2b/usersWhite.png";
import logoutWhite from "../Assets/img/icons/b2b/logoutWhite.png";
import logoutBlack from "../Assets/img/icons/b2b/logoutBlack.png";
import missionsWhite from "../Assets/img/icons/b2b/missionsWhite.png";
import missionsBlack from "../Assets/img/icons/b2b/missionsBlack.png";
import mantisChatWhite from "../Assets/img/icons/b2b/mantisChatWhite.png";
import mantisChatBlack from "../Assets/img/icons/b2b/mantisChatBlack.png";
import Employees from "./Employees";
import Archive from "./Archive";
import { businessLogout } from "../ApiCalls/authentication";
import { getFromStorage, setInStorage } from "../utils/storage";
import { userLogout } from "../Redux/modules/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getMantisBusinessNotificationNumber } from "../ApiCalls/mantisBusiness";
import BusinessNotifications from "./BusinessNotifications";
import { checkMantisBusinessUserPermission } from "../utils/businessUtils";
import BusinessUsers from "./BusinessUsers";
import BusinessAssistant from "./BusinessAssistant";
import BusinessIntegrations from "./BusinessIntegrations";
import { Modal } from "react-bootstrap";
import UsersSection from "./UsersSection";
import BusinessMissions from "./BusinessMissions";
import BusinessArchive from "./BusinessArchive";
import ArchivesSection from "./ArchivesSection";
import HelpPanel from "./HelpPanel";
import BusinessDashboard from "./BusinessDashboard";
import BusinessExtraction from "./BusinessExtraction";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout()),
  };
}

function BusinessBackOffice(props) {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const [sidebarExpandedWidth, setSidebarExpandedWidth] = useState(200);
  const [sidebarCollapsedWidth, setSidebarCollapsedWidth] = useState(100);
  const [sidebarTransitionTime, setSidebarTransitionTime] = useState(0.2);

  const [helpPanelExpandedWidth, setHelpPanelExpandedWidth] = useState(300);
  const [helpPanelCollapsedWidth, setHelpPanelCollapsedWidth] = useState(50);
  const [helpPanelTransitionTime, setHelpPanelTransitionTime] = useState(0.2);

  const [activeSection, setActiveSection] = useState(
    activeTab
      ? activeTab
      : props.mantisBusiness.enabledSections
      ? props.mantisBusiness.enabledSections[0]
      : "DASHBOARD"
  );

  useEffect(() => {
    console.log(activeTab);
    if (activeTab) {
      setActiveSection(activeTab.toUpperCase().replace(/-/g, "_"));
    }
  }, [activeTab]);

  // const [activeSection, setActiveSection] = useState(
  //   activeTab
  //     ? activeTab
  //     : props.mantisBusiness.enabledSections
  //     ? props.mantisBusiness.enabledSections[0]
  //     : "DASHBOARD"
  // );
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [helpPanelExpanded, setHelpPanelExpanded] = useState(false);
  const [hoverButtons, setHoverButtons] = useState({
    employees: false,
    archive: false,
    notifications: false,
    users: false,
    integrations: false,
    assistant: false,
    logout: false,
    profile: false,
    missions: false,
    dashboard: false,
    controlPanel: false,
  });
  const [notificationAmount, setNotificationAmount] = useState(0);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (activeSection !== "ASSISTANT") {
      setHelpPanelCollapsedWidth(0);
      setHelpPanelExpanded(false);
    } else {
      setHelpPanelCollapsedWidth(50);
      setHelpPanelExpandedWidth(400);
    }
  }, [activeSection]);

  useEffect(() => {
    let trueCount = Object.values(hoverButtons).filter((value) => value).length;
    if (trueCount > 1) {
      clearHoverButtons();
    }
  }, [hoverButtons]);

  /*  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 10000);
    return () => clearInterval(interval);
  }, []); */

  const clearHoverButtons = () => {
    setHoverButtons({
      employees: false,
      archive: false,
      notifications: false,
      users: false,
      integrations: false,
      assistant: false,
      logout: false,
      profile: false,
      missions: false,
      dashboard: false,
      controlPanel: false,
    });
  };

  const getNotifications = async () => {
    try {
      const notificationsRes = await getMantisBusinessNotificationNumber(
        props.mantisBusiness._id,
        props.token
      );
      if (notificationsRes.success) {
        setNotificationAmount(notificationsRes.notificationsAmount);
      }
    } catch (error) {}
  };

  const handleLogout = async () => {
    const localStorageToken = getFromStorage("mantis_app");

    if (localStorageToken && localStorageToken.token) {
      const { token } = localStorageToken;
      businessLogout(token);
      setInStorage("mantis_app", null);
      props.userLogout();
      navigate("/");
    }
  };

  return (
    <div className="business-backoffice-container">
      <div
        className="business-backoffice-sidebar"
        style={{
          width: sidebarExpanded
            ? `${sidebarExpandedWidth}px`
            : `${sidebarCollapsedWidth}px`,
          transition: `width ${sidebarTransitionTime}s`,
        }}
        onMouseEnter={() => {
          setSidebarExpanded(true);
        }}
        onMouseLeave={() => {
          setSidebarExpanded(false);
          setHoverButtons({
            employees: false,
            archive: false,
            notifications: false,
            users: false,
            integrations: false,
            assistant: false,
            logout: false,
          });
        }}
      >
        <div className="business-backoffice-top-buttons-container">
          {!sidebarExpanded ? (
            <img
              src={brand === "MANTIS" ? mantisWhiteLogo : sandyLogo}
              alt="Mantis logo"
              style={{
                height: "45px",
              }}
            />
          ) : (
            <img
              src={
                brand === "MANTIS" ? mantisWhiteCompleteLogo : sandyCompleteLogo
              }
              alt="Mantis logo"
              style={{
                height: "45px",
              }}
            />
          )}
        </div>
        <div className="business-backoffice-section-buttons-container">
          {props.mantisBusiness.enabledSections?.includes("ASSISTANT") && (
            <div
              className={
                activeSection === "ASSISTANT"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("ASSISTANT");
                navigate("/business/backoffice/assistant", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "ASSISTANT") {
                  setHoverButtons({ ...hoverButtons, assistant: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "ASSISTANT") {
                  setHoverButtons({ ...hoverButtons, assistant: false });
                }
              }}
            >
              <img
                src={
                  activeSection === "ASSISTANT"
                    ? mantisChatBlack
                    : hoverButtons.assistant
                    ? mantisChatBlack
                    : mantisChatWhite
                }
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                alt="Notifications icon"
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "ASSISTANT"
                        ? "#000"
                        : hoverButtons.assistant
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Chat mantis
                </h4>
              )}
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("DASHBOARD") && (
            <div
              className={
                activeSection === "DASHBOARD"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("DASHBOARD");
                navigate("/business/backoffice/dashboard?idDashboard=1", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "DASHBOARD") {
                  setHoverButtons({ ...hoverButtons, dashboard: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "DASHBOARD") {
                  setHoverButtons({ ...hoverButtons, dashboard: false });
                }
              }}
            >
              <img
                src={
                  activeSection === "DASHBOARD"
                    ? dashboardBlack
                    : hoverButtons.dashboard
                    ? dashboardBlack
                    : dashboardWhite
                }
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                alt="Archive icon"
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "DASHBOARD"
                        ? "#000"
                        : hoverButtons.dashboard
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Dashboard
                </h4>
              )}
            </div>
          )}

          {props.mantisBusiness.enabledSections?.includes("ARCHIVE") && (
            <div
              className={
                activeSection === "ARCHIVE"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("ARCHIVE");
                navigate("/business/backoffice/archive", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "ARCHIVE") {
                  setHoverButtons({ ...hoverButtons, archive: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "ARCHIVE") {
                  setHoverButtons({ ...hoverButtons, archive: false });
                }
              }}
            >
              <img
                src={
                  activeSection === "ARCHIVE"
                    ? folderBlack
                    : hoverButtons.archive
                    ? folderBlack
                    : folderWhite
                }
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                alt="Archive icon"
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "ARCHIVE"
                        ? "#000"
                        : hoverButtons.archive
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Archivo
                </h4>
              )}
            </div>
          )}

          {props.mantisBusiness.enabledSections?.includes("CONTROL_PANEL") && (
            <div
              className={
                activeSection === "CONTROL_PANEL"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("CONTROL_PANEL");
                navigate("/business/backoffice/control-panel", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "CONTROL_PANEL") {
                  setHoverButtons({ ...hoverButtons, controlPanel: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "CONTROL_PANEL") {
                  setHoverButtons({ ...hoverButtons, controlPanel: false });
                }
              }}
            >
              <img
                src={
                  activeSection === "CONTROL_PANEL"
                    ? missionsBlack
                    : hoverButtons.controlPanel
                    ? missionsBlack
                    : missionsWhite
                }
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                alt="Archive icon"
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "CONTROL_PANEL"
                        ? "#000"
                        : hoverButtons.controlPanel
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Centro de control
                </h4>
              )}
            </div>
          )}

          {/* <div
          style={
            activeSection === "ASSISTANT"
              ? {
                  height: "85px",
                  width: "85px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #f0f56c",
                  position: "relative",
                  left: -12,
                  backgroundColor: "#f0f56c",
                }
              : hoverButtons.assistant
              ? {
                  height: "85px",
                  width: "85px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #f0f56c",
                  position: "relative",
                  left: -12,
                  backgroundColor: "#f0f56c",
                }
              : {
                  height: "85px",
                  width: "85px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid #f0f56c",
                  position: "relative",
                  left: -12,
                }
          }
          onClick={() => {
            setActiveSection("ASSISTANT");
            clearHoverButtons();
          }}
          onMouseEnter={() => {
            if (activeSection !== "ASSISTANT") {
              setHoverButtons({ ...hoverButtons, assistant: true });
            }
          }}
          onMouseLeave={() => {
            if (activeSection !== "ASSISTANT") {
              setHoverButtons({ ...hoverButtons, assistant: false });
            }
          }}
        >
          <img
            src={
              activeSection === "ASSISTANT"
                ? mantisBlackLogo
                : hoverButtons.assistant
                ? mantisBlackLogo
                : mantisWhiteLogo
            }
            alt="Mantis icon"
            className="business-sidebar-tab-icon"
          />
        </div> */}

          {props.mantisBusiness.enabledSections?.includes("USERS") && (
            <div
              className={
                activeSection === "USERS"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("USERS");
                navigate("/business/backoffice/users", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "USERS") {
                  setHoverButtons({ ...hoverButtons, users: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "USERS") {
                  setHoverButtons({ ...hoverButtons, users: false });
                }
              }}
            >
              <img
                src={
                  activeSection === "USERS"
                    ? usersBlack
                    : hoverButtons.users
                    ? usersBlack
                    : usersWhite
                }
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                alt="Users icon"
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "USERS"
                        ? "#000"
                        : hoverButtons.users
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Usuarios
                </h4>
              )}
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("INTEGRATIONS") && (
            <div
              className={
                activeSection === "INTEGRATIONS"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("INTEGRATIONS");
                navigate("/business/backoffice/integrations", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "INTEGRATIONS") {
                  setHoverButtons({ ...hoverButtons, integrations: true });
                }
              }}
              onMouseLeave={() =>
                setHoverButtons({ ...hoverButtons, integrations: false })
              }
            >
              <img
                src={
                  activeSection === "INTEGRATIONS"
                    ? integrationsBlack
                    : hoverButtons.integrations
                    ? integrationsBlack
                    : integrationsWhite
                }
                alt="Integrations icon"
                style={{
                  marginRight: sidebarExpanded ? "10px" : "0px",
                }}
                className="business-sidebar-tab-icon"
              />
              {sidebarExpanded && (
                <h4
                  className="business-sidebar-tab-title"
                  style={{
                    color:
                      activeSection === "INTEGRATIONS"
                        ? "#000"
                        : hoverButtons.integrations
                        ? "#000"
                        : "#fff",
                  }}
                >
                  Datos
                </h4>
              )}
            </div>
          )}
        </div>

        <div className="business-backoffice-bottom-buttons-container">
          <div
            style={{
              height: "85px",
            }}
            className={
              activeSection === "PROFILE"
                ? "business-sidebar-active-tab-button"
                : "business-sidebar-tab-button"
            }
            onMouseEnter={() => {
              if (activeSection !== "PROFILE") {
                setHoverButtons({ ...hoverButtons, profile: true });
              }
            }}
            onMouseLeave={() =>
              setHoverButtons({ ...hoverButtons, profile: false })
            }
          >
            <img
              src={props.mantisBusiness.logo || mantisLogo}
              alt="Business logo"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "white",
              }}
            />
            {sidebarExpanded && (
              <h4
                className="business-sidebar-tab-title"
                style={{
                  color:
                    activeSection === "PROFILE"
                      ? "#000"
                      : hoverButtons.profile
                      ? "#000"
                      : "#fff",
                }}
              >
                Perfil
              </h4>
            )}
          </div>
          <div
            className="business-sidebar-tab-button"
            style={{
              position: "relative",
              justifySelf: "flex-end",
              justifyContent: sidebarExpanded ? "flex-start" : "center",
              paddingLeft: sidebarExpanded ? "10px" : "0px",
            }}
            onClick={() => setShowLogoutModal(true)}
            onMouseEnter={() =>
              setHoverButtons({ ...hoverButtons, logout: true })
            }
            onMouseLeave={() =>
              setHoverButtons({ ...hoverButtons, logout: false })
            }
          >
            <img
              src={hoverButtons.logout ? logoutBlack : logoutWhite}
              alt="Logout icon"
              className="business-sidebar-tab-icon"
              style={{
                marginRight: sidebarExpanded ? "10px" : "0px",
              }}
            />
            {sidebarExpanded && (
              <h4
                className="business-sidebar-tab-title"
                style={{
                  color: hoverButtons.logout ? "#000" : "#fff",
                }}
              >
                Cerrar sesión
              </h4>
            )}
          </div>
        </div>
      </div>
      {props.mantisBusiness.approved ? (
        <div
          className="business-backoffice-viewpanel"
          style={{
            width:
              sidebarExpanded && !helpPanelExpanded
                ? `calc(100vw - ${sidebarExpandedWidth}px)`
                : sidebarExpanded && helpPanelExpanded
                ? `calc(100vw - ${
                    sidebarExpandedWidth + helpPanelExpandedWidth
                  }px)`
                : !sidebarExpanded && helpPanelExpanded
                ? `calc(100vw - ${
                    helpPanelExpandedWidth + sidebarCollapsedWidth
                  }px)`
                : `calc(100vw - ${
                    sidebarCollapsedWidth + helpPanelCollapsedWidth
                  }px)`,
            left: sidebarExpanded
              ? `${sidebarExpandedWidth}px`
              : `${sidebarCollapsedWidth}px`,
            transition: `width ${sidebarTransitionTime}s, left ${sidebarTransitionTime}s`,
            paddingTop: activeSection === "ASSISTANT" ? "0px" : "20px",
          }}
        >
          {activeSection === "EMPLOYEES" && <Employees />}
          {activeSection === "DASHBOARD" && <BusinessDashboard />}
          {activeSection === "ARCHIVE" && (
            <>
              {/* <Archive archiveUserId={archiveUserId} /> */}
              <ArchivesSection />
            </>
          )}
          {activeSection === "NOTIFICATIONS" && <BusinessNotifications />}
          {activeSection === "USERS" && <UsersSection />}
          {activeSection === "ASSISTANT" && (
            <BusinessAssistant
              sidebarExpanded={sidebarExpanded}
              helpPanelExpanded={helpPanelExpanded}
              sidebarCollapsedWidth={sidebarCollapsedWidth}
              sidebarExpandedWidth={sidebarExpandedWidth}
              helpPanelExpandedWidth={helpPanelExpandedWidth}
              helpPanelCollapsedWidth={helpPanelCollapsedWidth}
              sidebarTransitionTime={sidebarTransitionTime}
            />
          )}
          {activeSection === "INTEGRATIONS" && <BusinessIntegrations />}
          {activeSection === "CONTROL_PANEL" && <BusinessExtraction />}
          {/* <div className="yellow-blur"></div>
          <div className="blue-blur"></div> */}
        </div>
      ) : (
        <div
          className="business-backoffice-viewpanel"
          style={{
            width:
              sidebarExpanded && !helpPanelExpanded
                ? `calc(100vw - ${sidebarExpandedWidth}px)`
                : sidebarExpanded && helpPanelExpanded
                ? `calc(100vw - ${
                    sidebarExpandedWidth + helpPanelExpandedWidth
                  }px)`
                : !sidebarExpanded && helpPanelExpanded
                ? `calc(100vw - ${
                    helpPanelExpandedWidth + sidebarCollapsedWidth
                  }px)`
                : `calc(100vw - ${
                    sidebarCollapsedWidth + helpPanelCollapsedWidth
                  }px)`,
            left: sidebarExpanded
              ? `${sidebarExpandedWidth}px`
              : `${sidebarCollapsedWidth}px`,
            transition: `width ${sidebarTransitionTime}s, left ${sidebarTransitionTime}s`,
            paddingTop: activeSection === "ASSISTANT" ? "0px" : "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h2
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
              }}
            >
              ¡Tu cuenta de empresa está en proceso de verificación!
            </h2>
          </div>
          <div className="yellow-blur"></div>
          <div className="blue-blur"></div>
        </div>
      )}
      <HelpPanel
        expanded={helpPanelExpanded}
        setExpanded={setHelpPanelExpanded}
        helpPanelExpandedWidth={helpPanelExpandedWidth}
        helpPanelCollapsedWidth={helpPanelCollapsedWidth}
        helpPanelTransitionTime={helpPanelTransitionTime}
      />
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <h4
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
                marginBottom: 20,
              }}
            >
              ¿Estás seguro que deseas cerrar sesión?
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <button
                className="business-logout-button"
                onClick={() => handleLogout()}
              >
                Sí
              </button>
              <button
                className="business-logout-button"
                onClick={() => setShowLogoutModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default connect(select, mapDispatchToProps)(BusinessBackOffice);
