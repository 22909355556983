import { backUrl } from "../utils/backURL";
import { fetchWrapper } from "../utils/fetchWrapper";

export const getEmployees = async (requesterId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: false,
    }),
  });

  return res;
};

export const getActiveEmployees = async (requesterId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/getEmployees/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      activeEmployees: true,
    }),
  });

  return res;
};

export const createEmployee = async (requesterId, token, employeeData) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/createEmployee/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mantisBusinessId: requesterId,
      employeeData,
    }),
  });

  return res;
};

export const getEmployeeFolders = async (userId, mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getEmployeeFolders/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getBusinessFolders = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getBusinessFolders/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getBusinessEmails = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getBusinessEmails/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getEmployeeFolderById = async (
  folderId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getEmployeeFolderById/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getBusinessFolderById = async (
  folderId,
  token,
  mantisBusinessId,
  page
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getBusinessFolderById/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        mantisBusinessId,
        page,
      }),
    }
  );

  return res;
};

export const createEmployeeFolder = async (
  parentFolderId,
  folderData,
  mantisBusinessId,
  userId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createEmployeeFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        parentFolderId,
        folderData,
        mantisBusinessId,
        userId,
      }),
    }
  );

  return res;
};

export const createBusinessFolder = async (
  token,
  parentFolderId,
  folderData,
  mantisBusinessId,
  mantisBusinessUserId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createBusinessFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        parentFolderId,
        folderData,
        mantisBusinessId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const createEmployeeDocument = async (
  folderId,
  base64,
  name,
  type,
  mantisBusinessId,
  userId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createEmployeeDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        type,
        mantisBusinessId,
        userId,
      }),
    }
  );

  return res;
};

export const createBusinessDocument = async (
  folderId,
  base64,
  name,
  type,
  fileDate,
  fileSize,
  mantisBusinessId,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createBusinessDocument/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        type,
        mantisBusinessId,
        mantisBusinessUserId,
        fileDate,
        fileSize,
      }),
    }
  );

  return res;
};

export const createBusinessCompressedFolder = async (
  folderId,
  base64,
  name,
  mantisBusinessId,
  mantisBusinessUserId,
  level,
  token,
  fileDate
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createBusinessCompressedFolder/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        folderId,
        base64,
        name,
        mantisBusinessId,
        mantisBusinessUserId,
        level,
        fileDate,
      }),
    }
  );

  return res;
};

export const getMantisBusinessNotificationNumber = async (
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/notifications/getMantisBusinessUnreadNotificationsAmount`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getMantisBusinessNotifications = async (
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/notifications/getMantisBusinessNotifications`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const sendCellphoneVerification = async (cellphone) => {
  const res = await fetchWrapper(
    `${backUrl}/verify/sendBusinessCellphoneVerification`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cellphone }),
    }
  );

  return res;
};

export const verifyCellphone = async (cellphone, code) => {
  const res = await fetchWrapper(
    `${backUrl}/verify/verifyMantisBusinessCellphone`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cellphone, code }),
    }
  );

  return res;
};

export const getBusinessPermissions = async (
  requesterId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getBusinessPermissions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const createMantisBusinessUser = async (
  mantisBusinessId,
  mantisBusinessUserId,
  token,
  userData
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/createMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisBusinessUserId,
        userData,
      }),
    }
  );

  return res;
};

export const editMantisBusinessUser = async (
  mantisBusinessId,
  mantisBusinessUserId,
  token,
  userData
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/editMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisBusinessUserId,
        userData,
      }),
    }
  );

  return res;
};

export const getMantisBusinessUsers = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getMantisBusinessUsers`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const deleteMantisBusinessUser = async (
  mantisBusinessUserToDeleteId,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/deleteMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessUserToDeleteId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const updateMantisBusinessUser = async (
  mantisBusinessUserId,
  mantisBusinessUserToUpdateId,
  userData,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/updateMantisBusinessUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessUserId,
        mantisBusinessUserToUpdateId,
        userData,
      }),
    }
  );

  return res;
};

export const sendRecoverPasswordEmail = async (email) => {
  const res = await fetchWrapper(`${backUrl}/passwordReset/business`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  return res;
};

export const resetPassword = async (token, newPassword) => {
  const resetPasswordReq = await fetchWrapper(
    `${backUrl}/passwordReset/business/changePassword`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        newPassword,
      }),
    }
  );
  const response = await resetPasswordReq.json();
  return response;
};

export const getLatestAssistantConversation = async (
  mantisBusinessUserId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getLatestAssistantConversation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessUserId, mantisBusinessId }),
    }
  );

  return res;
};

export const getAssisstantConversationsHistory = async (
  mantisBusinessUserId,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getAssistantConversationsHistory`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessUserId, mantisBusinessId }),
    }
  );
  return res;
};

export const getAssistantConversationById = async (
  businessAssistantConversationId,
  mantisBusinessId,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getAssistantConversationById`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        businessAssistantConversationId,
        mantisBusinessId,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const postAssistantMessage = async (
  cellPhone,
  message,
  token,
  mantisBusinessUserId,
  mantisBusinessId,
  searchType
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/postAssistantMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        cellPhone,
        message,
        mantisBusinessUserId,
        mantisBusinessId,
        searchType,
      }),
    }
  );

  return res;
};

export const getVisionResponse = async function (
  files,
  prompt,
  token,
  mantisBusinessId,
  mantisBusinessUserId,
  mantisMissionId,
  bulk,
  missionName,
  missionDescription
) {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getVisionResponse`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        files,
        prompt,
        mantisBusinessId,
        mantisBusinessUserId,
        mantisMissionId,
        bulk,
        missionName,
        missionDescription,
      }),
    }
  );

  return res;
};

export const getMantisMissions = async (mantisBusinessId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/getMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const exportMantisMissions = async (mantisMissionId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/exportMantisMissionsByBusinessId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisMissionId,
      }),
    }
  );

  return res;
};

export const getMantisMissionResults = async (mantisMissionId, token) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/getMantisMissionResults`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisMissionId }),
    }
  );

  return res;
};

export const saveMantisMission = async (
  mantisBusinessId,
  mantisMission,
  mantisBusinessUserId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisMissions/saveMantisMission`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        mantisMission,
        mantisBusinessUserId,
      }),
    }
  );

  return res;
};

export const updateFolder = async (
  folderId,
  folderData,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/updateFolder/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      folderId,
      folderData,
      mantisBusinessId,
    }),
  });

  return res;
};

export const deleteFolder = async (folderId, mantisBusinessId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/deleteFolder/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      folderId,
      mantisBusinessId,
    }),
  });

  return res;
};

export const updateDocument = async (
  documentId,
  documentData,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/updateDocument/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      documentId,
      documentData,
      mantisBusinessId,
    }),
  });

  return res;
};

export const deleteDocument = async (documentId, mantisBusinessId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/deleteDocument/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      documentId,
      mantisBusinessId,
    }),
  });

  return res;
};

export const getExtractedData = async (
  mantisBusinessId,
  token,
  fileCategory,
  currentPage,
  registersPerPage,
  tab
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getExtractedData/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        fileCategory,
        currentPage,
        registersPerPage,
        tab,
      }),
    }
  );

  return res;
};

export const filterOffices = async (
  token,
  filters,
  registersPerPage,
  mantisBusinessId,
  tab
) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/filterOffices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      filters,
      registersPerPage,
      tab,
      mantisBusinessId,
    }),
  });

  return res;
};

export const getDocumentById = async (documentId, mantisBusinessId, token) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/getDocumentById/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      documentId,
      mantisBusinessId,
    }),
  });

  return res;
};

export const getDocumentsByIds = async (
  documentIds,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getDocumentsByIds/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        documentIds,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const getDocumentsAndEmailsByIds = async (
  ids,
  mantisBusinessId,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getDocumentsAndEmailsByIds/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const feedbackAssistantMessage = async (
  mantisBusinessId,
  businessAssistantConversationId,
  assistantResponse,
  businessQuery,
  feedbackType,
  responseIndex,
  token
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/feedbackAssistantMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        mantisBusinessId,
        businessAssistantConversationId,
        assistantResponse,
        businessQuery,
        feedbackType,
        responseIndex,
      }),
    }
  );

  return res;
};

export const getRelatedDocumentGroup = async (
  token,
  relatedDocumentsGroupId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getRelatedDocumentsGroup`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ relatedDocumentsGroupId, mantisBusinessId }),
    }
  );

  return res;
};

export const getDocumentAlerts = async (
  token,
  relatedDocumentsGroupId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getRelatedDocumentsGroupsAlert`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ relatedDocumentsGroupId, mantisBusinessId }),
    }
  );

  return res;
};

export const updateExtractionField = async (
  token,
  field,
  value,
  mainKey,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/updateExtractionField`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ key: field, value, mainKey, mantisBusinessId }),
    }
  );

  return res;
};

export const sendDocumentsEmail = async (
  token,
  subject,
  message,
  recipientName,
  documentIds,
  mantisBusinessId,
  email
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sendDocumentsEmail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject,
        message,
        recipientName,
        documentIds,
        mantisBusinessId,
        email,
      }),
    }
  );

  return res;
};

export const sendTranslatableEmail = async (
  token,
  subject,
  message,
  recipientName,
  documentIds,
  mantisBusinessId,
  email,
  language
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/sendTranslatableEmail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subject,
        message,
        recipientName,
        documentIds,
        mantisBusinessId,
        email,
        language,
      }),
    }
  );

  return res;
};

export const downloadOfficesExcel = async (
  token,
  mantisBusinessId,
  dataToExport,
  option,
  subOption
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/downloadOfficesExcel`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dataToExport,
        option,
        subOption,
        mantisBusinessId,
      }),
    }
  );

  return res;
};

export const downloadExcel = async (
  token,
  mantisBusinessId,
  type,
  dataToExport
) => {
  const res = await fetchWrapper(`${backUrl}/mantisBusiness/downloadExcel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ mantisBusinessId, type, dataToExport }),
  });

  return res;
};

export const getGmailConnections = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/gmailConnections/getBusinessConnections`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const deleteGmailConnection = async (token, connectionId) => {
  const res = await fetchWrapper(
    `${backUrl}/gmailConnections/deleteBusinessConnection`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ connectionId }),
    }
  );

  return res;
};

export const getExtractionFields = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getExtractionFields`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getExtractionDropdownOptions = async (token, mantisBusinessId) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getExtractionDropdownOptions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mantisBusinessId }),
    }
  );

  return res;
};

export const getDocumentSignedUrl = async (
  token,
  documentId,
  mantisBusinessId
) => {
  const res = await fetchWrapper(
    `${backUrl}/mantisBusiness/getDocumentSignedUrl`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ documentId, mantisBusinessId }),
    }
  );

  return res;
};
