import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import { Form } from "react-bootstrap";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./styles/businessBackofficeStyles.scss";

import {
  createMantisBusinessUser,
  editMantisBusinessUser,
  getMantisBusinessUsers,
} from "../ApiCalls/mantisBusiness";
import { checkMantisBusinessUserPermission } from "../utils/businessUtils";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function BusinessUsers(props) {
  const pageSize = 10;

  const [mantisBusinessUsers, setMantisBusinessUsers] = useState([]);
  const [creatingNewUser, setCreatingNewUser] = useState(false);
  const [loadingCreatingNewUser, setLoadingCreatingNewUser] = useState(false);
  const [newUserForm, setNewUserForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    cellPhone: "",
    permissions: [],
  });
  const [loading, setLoading] = useState(true);
  const [newUserFormValidated, setNewUserFormValidated] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loadingEditingUser, setLoadingEditingUser] = useState(false);
  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [editingUserFormValidated, setEditingUserFormValidated] =
    useState(false);

  useEffect(() => {
    getBusinessUsers();
  }, []);

  useEffect(() => {
    let permissions = [];
    if (editingUser) {
      let userPermissions = editingUser.permissions;
      let userPermissionsPermissions = userPermissions.map((permission) => {
        return permission.permission;
      });
      props.mantisBusiness.enabledPermissions.map((permission) => {
        if (!userPermissionsPermissions.includes(permission.permission)) {
          permissions.push({
            value: permission,
            label: permission.friendlyName,
          });
        }
      });
    }
    setPermissionsOptions(permissions);
  }, [editingUser]);

  useEffect(() => {
    if (
      newUserForm.email.length > 0 &&
      newUserForm.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
      newUserForm.password.length > 0 &&
      newUserForm.confirmPassword.length > 0 &&
      newUserForm.password === newUserForm.confirmPassword
    ) {
      setDataValidated(true);
    } else {
      setDataValidated(false);
    }
  }, [newUserForm]);

  useEffect(() => {
    if (editingUser) {
      if (
        editingUser.email.length > 0 &&
        editingUser.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
        editingUser.password.length > 0 &&
        editingUser.confirmPassword.length > 0 &&
        editingUser.password === (editingUser.confirmPassword || "")
      ) {
        setDataValidated(true);
      } else {
        setDataValidated(false);
      }
    }
  }, [editingUser]);

  function clearNewUserForm() {
    setNewUserForm({
      email: "",
      password: "",
      confirmPassword: "",
      permissions: [],
      cellPhone: "",
    });
    setNewUserFormValidated(false);
  }

  async function getBusinessUsers() {
    const res = await getMantisBusinessUsers(
      props.mantisBusiness._id,
      props.token
    );
    console.log(res);
    if (res.success) {
      setMantisBusinessUsers(res.users);
    }
    setLoading(false);
  }

  async function handleCreateNewUser(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setNewUserFormValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setLoadingCreatingNewUser(true);
    const res = await createMantisBusinessUser(
      props.mantisBusiness._id,
      props.user._id,
      props.token,
      {
        ...newUserForm,
        cellPhone: newUserForm.cellPhone.replace(/\s/g, "").replace("+", ""),
      }
    );
    if (res.success) {
      setMantisBusinessUsers([...mantisBusinessUsers, res.user]);
      setCreatingNewUser(false);
      setLoadingCreatingNewUser(false);
      clearNewUserForm();
    } else {
      setLoadingCreatingNewUser(false);
      alert("Error creando usuario: " + res.message || "Error desconocido");
    }
  }

  async function handleEditUser(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setEditingUserFormValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (
      (editingUser.password !== "" || editingUser.confirmPassword !== "") &&
      editingUser.password !== editingUser.confirmPassword
    ) {
      alert("Las contraseñas no coinciden");
      return;
    }
    setLoadingEditingUser(true);
    const res = await editMantisBusinessUser(
      props.mantisBusiness._id,
      props.user._id,
      props.token,
      {
        ...editingUser,
        cellPhone: editingUser.cellPhone.replace(/\s/g, "").replace("+", ""),
      }
    );
    if (res.success) {
      let mantisBusinessUsersCopy = [...mantisBusinessUsers];
      const index = mantisBusinessUsersCopy.findIndex(
        (user) => user._id === res.user._id
      );
      mantisBusinessUsersCopy[index] = res.user;
      setMantisBusinessUsers(mantisBusinessUsersCopy);
      setEditingUser(null);
      setLoadingEditingUser(false);
    } else {
      setLoadingEditingUser(false);
      alert("Error editando usuario: " + res.message || "Error desconocido");
    }
  }

  function handleCreateNewUserInputChange(event) {
    setNewUserForm({
      ...newUserForm,
      [event.target.name]: event.target.value,
    });
  }

  function handleEditUserInputChange(event) {
    setEditingUser({
      ...editingUser,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div>
      <Row>
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          <Row>
            {true && (
              <>
                <Col xs={6}>
                  {!creatingNewUser &&
                    !editingUser &&
                    checkMantisBusinessUserPermission(
                      props.user,
                      "CREATE_USERS"
                    ) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div
                          className="add-employee-button"
                          onClick={() => {
                            setCreatingNewUser(true);
                          }}
                        >
                          +
                        </div>
                        <h5 className="add-employee-text">Agregar usuario</h5>
                      </div>
                    )}
                  {(creatingNewUser || editingUser) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        className="add-employee-button"
                        onClick={() => {
                          setCreatingNewUser(false);
                          setEditingUser(null);
                        }}
                      >
                        {"<"}
                      </div>
                      <h5 className="add-employee-text">Regresar</h5>
                    </div>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={11} style={{ margin: "auto" }}>
          <div>
            {loading ? (
              <Spinner color="white" />
            ) : (
              <>
                {!creatingNewUser && !editingUser && (
                  <Table>
                    <thead>
                      <tr
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "white",
                        }}
                      >
                        <th>Correo electrónico</th>
                        <th>Celular</th>
                        <th>Fecha de Creación</th>
                        <th>Permisos</th>
                        {checkMantisBusinessUserPermission(
                          props.user,
                          "EDIT_USERS"
                        ) && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {mantisBusinessUsers.map((user, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontSize: "14px",
                              color: "white",
                            }}
                          >
                            <td>{user.email}</td>
                            <td>
                              {user.cellPhone ? "+" + user.cellPhone : "-"}
                            </td>
                            <td>
                              {new Date(user.dateCreated).toLocaleDateString(
                                "es"
                              )}
                            </td>
                            <td>
                              {user.permissions
                                ? user.permissions.map((permission, index) => {
                                    return `${permission.friendlyName}${
                                      index < user.permissions.length - 1
                                        ? ", "
                                        : ""
                                    }`;
                                  })
                                : ""}
                            </td>
                            {checkMantisBusinessUserPermission(
                              props.user,
                              "EDIT_USERS"
                            ) && (
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditingUser({
                                      ...user,
                                      password: "",
                                      confirmPassword: "",
                                    });
                                  }}
                                >
                                  <i className="fas fa-edit" color="white"></i>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {creatingNewUser && (
                  <div>
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      Crea un nuevo usuario
                    </h3>
                    <Form
                      onSubmit={handleCreateNewUser}
                      style={{ margin: "auto" }}
                      noValidate
                      validated={newUserFormValidated}
                    >
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="text"
                              name="email"
                              id="email"
                              value={newUserForm.email}
                              placeholder="Correo electrónico"
                              onChange={handleCreateNewUserInputChange}
                              required
                              isInvalid={
                                newUserForm.email.length > 0 &&
                                !newUserForm.email.match(
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                )
                              }
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Formato incorrecto
                            </Form.Control.Feedback>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          {/* <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="text"
                              name="cellPhone"
                              id="cellPhone"
                              value={newUserForm.cellPhone}
                              placeholder="Teléfono celular (incluye código de país)"
                              onChange={handleCreateNewUserInputChange}
                              required
                              isInvalid={
                                newUserForm.cellPhone.length > 0 &&
                                newUserForm.cellPhone.length < 11
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Teléfono celular requerido
                            </Form.Control.Feedback>
                          </FormGroup> */}
                          <PhoneInput
                            className="new-employee-form-input"
                            defaultCountry={"CO"}
                            placeholder="Celular de contacto"
                            value={newUserForm.cellPhone}
                            onChange={(e) => {
                              if (!e) {
                                e = "";
                              }
                              setNewUserForm({
                                ...newUserForm,
                                cellPhone: e,
                              });
                            }}
                          />
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Contraseña"
                              value={newUserForm.password}
                              onChange={handleCreateNewUserInputChange}
                              required
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Contraseña requerida
                            </Form.Control.Feedback>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              value={newUserForm.confirmPassword}
                              onChange={handleCreateNewUserInputChange}
                              required
                              invalid={
                                newUserForm.password !==
                                newUserForm.confirmPassword
                              }
                              placeholder="Confirmar contraseña"
                            />
                          </FormGroup>
                          <Form.Control.Feedback
                            type="invalid"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            Contraseña requerida
                          </Form.Control.Feedback>
                        </Col>
                        <hr />
                        <h4
                          style={{
                            textAlign: "left",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "400",
                            fontSize: "18px",
                            color: "white",
                          }}
                        >
                          Permisos
                        </h4>
                        <Select
                          options={permissionsOptions}
                          closeMenuOnSelect={false}
                          isMulti
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              fontFamily: "Poppins, sans-serif",
                            }),
                          }}
                          placeholder="Selecciona los permisos..."
                          onChange={(selectedPermissions) => {
                            setNewUserForm({
                              ...newUserForm,
                              permissions: selectedPermissions.map(
                                (permission) => permission.value
                              ),
                            });
                          }}
                        />
                        {dataValidated && (
                          <Col
                            xs={12}
                            style={{ margin: "auto", marginTop: 30 }}
                          >
                            <Button
                              className="create-new-employee-button"
                              type="submit"
                            >
                              {loadingCreatingNewUser ? (
                                <Spinner size={"sm"} />
                              ) : (
                                "Crear usuario"
                              )}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  </div>
                )}
                {editingUser && (
                  <div>
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      Editar usuario
                    </h3>
                    <Form
                      onSubmit={handleEditUser}
                      style={{ margin: "auto" }}
                      noValidate
                      validated={editingUserFormValidated}
                    >
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="text"
                              name="email"
                              id="email"
                              value={editingUser.email}
                              placeholder="Correo electrónico"
                              onChange={handleEditUserInputChange}
                              required
                              isInvalid={
                                editingUser.email.length > 0 &&
                                !editingUser.email.match(
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                )
                              }
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Formato incorrecto
                            </Form.Control.Feedback>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          {/* <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="text"
                              name="cellPhone"
                              id="cellPhone"
                              value={newUserForm.cellPhone}
                              placeholder="Teléfono celular (incluye código de país)"
                              onChange={handleCreateNewUserInputChange}
                              required
                              isInvalid={
                                newUserForm.cellPhone.length > 0 &&
                                newUserForm.cellPhone.length < 11
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Teléfono celular requerido
                            </Form.Control.Feedback>
                          </FormGroup> */}
                          <PhoneInput
                            className="new-employee-form-input"
                            defaultCountry={"CO"}
                            placeholder="Celular de contacto"
                            value={editingUser.cellPhone}
                            onChange={(e) => {
                              if (!e) {
                                e = "";
                              }
                              setEditingUser({
                                ...editingUser,
                                cellPhone: e,
                              });
                            }}
                          />
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Contraseña"
                              value={editingUser.password}
                              onChange={handleEditUserInputChange}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Contraseña requerida
                            </Form.Control.Feedback>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <Form.Control
                              className="new-employee-form-input"
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              value={editingUser.confirmPassword}
                              onChange={handleEditUserInputChange}
                              invalid={
                                editingUser.password !==
                                editingUser.confirmPassword
                              }
                              placeholder="Confirmar contraseña"
                            />
                          </FormGroup>
                          <Form.Control.Feedback
                            type="invalid"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            Contraseña requerida
                          </Form.Control.Feedback>
                        </Col>
                        <hr />
                        <h4
                          style={{
                            textAlign: "left",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "400",
                            fontSize: "18px",
                            color: "white",
                          }}
                        >
                          Permisos
                        </h4>
                        <Select
                          options={permissionsOptions.filter(
                            (permission) =>
                              !editingUser.permissions.includes(
                                permission.value
                              )
                          )}
                          closeMenuOnSelect={false}
                          isMulti
                          value={editingUser.permissions.map((permission) => {
                            return {
                              value: permission,
                              label: permission.friendlyName,
                            };
                          })}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              fontFamily: "Poppins, sans-serif",
                            }),
                          }}
                          placeholder="Selecciona los permisos..."
                          onChange={(selectedPermissions) => {
                            setEditingUser({
                              ...editingUser,
                              permissions: selectedPermissions.map(
                                (permission) => permission.value
                              ),
                            });
                          }}
                        />

                        <Col xs={12} style={{ margin: "auto", marginTop: 30 }}>
                          <Button
                            className="create-new-employee-button"
                            type="submit"
                          >
                            {loadingEditingUser ? (
                              <Spinner size={"sm"} />
                            ) : (
                              "Editar usuario"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(BusinessUsers);
