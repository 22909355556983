import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import "./styles/businessBackofficeStyles.scss";
import bambooLogo from "../Assets/img/icons/b2b/integrationLogos/bambooHRLogo.png";
import gmailLogo from "../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import outlookLogo from "../Assets/img/icons/b2b/integrationLogos/outlookLogo.png";
import {
  deleteGmailConnection,
  getGmailConnections,
} from "../ApiCalls/mantisBusiness";
import { backUrl } from "../utils/backURL";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessIntegrations(props) {
  const [loading, setLoading] = useState(false);
  const [gmailConnections, setGmailConnections] = useState([]);
  const [deletingGmailConnection, setDeletingGmailConnection] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const response = await getGmailConnections(
      props.token,
      props.mantisBusiness._id
    );
    console.log(response);
    if (response.success) {
      setGmailConnections(response.connections);
    }
    setLoading(false);
  };

  const handleDeleteGmailConnection = async (connectionId) => {
    const res = await deleteGmailConnection(props.token, connectionId);
    if (res.success) {
      getData();
    } else {
      alert("Error al eliminar la conexión");
    }
  };

  return (
    <div>
      <Row>
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          <Row>
            <Col xs={6}>
              <h1 className="business-viewpanel-title">Fuentes de datos</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <h2 className="business-viewpanel-subtitle">
            Conecta tus fuentes de información
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          {/* <Col xs={2}>
          <div className="business-integrations-integration-container">
            <img
              src={bambooLogo}
              alt="bambooHR"
              className="business-integrations-integration-logo"
            />
            <h3 className="business-integrations-integration-title">
              Bamboo HR
            </h3>
          </div>
        </Col> */}
          <Col xs={2}>
            <div
              className="business-integrations-integration-container"
              onClick={() => {
                window.open(
                  `${backUrl}/googleOAuth/auth?mantisBusinessId=${props.mantisBusiness._id}`
                );
              }}
            >
              <img
                src={gmailLogo}
                alt="Gmail"
                className="business-integrations-integration-logo"
              />
              <h3 className="business-integrations-integration-title">Gmail</h3>
            </div>
          </Col>
          {/* <Col xs={2}>
          <div className="business-integrations-integration-container">
            <img
              src={outlookLogo}
              alt="Outlook"
              className="business-integrations-integration-logo"
            />
            <h3 className="business-integrations-integration-title">Outlook</h3>
          </div>
        </Col> */}
        </Col>
      </Row>
      <Row>
        <Col xs={11} style={{ margin: "auto", marginTop: 20 }}>
          <h2 className="business-viewpanel-subtitle">Cuentas conectadas</h2>
        </Col>
      </Row>
      <Row>
        {loading ? (
          <Col xs={11} style={{ margin: "auto" }}>
            <Spinner color="white" />
          </Col>
        ) : (
          <Col xs={11} style={{ margin: "auto" }}>
            {gmailConnections.length > 0 ? (
              <Table>
                <thead>
                  <tr
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      color: "white",
                    }}
                  >
                    <th></th>
                    <th>Nombre</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {gmailConnections.map((connection) => {
                    let expiryDate = new Date(connection.expiryDate);
                    expiryDate.setDate(expiryDate.getDate() + 7);
                    console.log(expiryDate);
                    let isExpired = new Date() >= expiryDate;
                    return (
                      <tr
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          color: "white",
                          verticalAlign: "middle",
                        }}
                      >
                        <td>
                          {" "}
                          <img
                            src={gmailLogo}
                            alt="Gmail"
                            className="business-integrations-integration-logo-small"
                          />
                        </td>
                        <td>{connection.email}</td>
                        <td
                          style={{
                            color: isExpired ? "red" : "green",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (isExpired) {
                              window.open(
                                `https://services.mantisapp.co/api/googleOAuth/auth?mantisBusinessId=${props.mantisBusiness._id}`
                              );
                            }
                          }}
                        >
                          {isExpired ? "Expirado" : "Activo"}
                        </td>
                        <td
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleDeleteGmailConnection(connection._id);
                            }}
                          >
                            {deletingGmailConnection ? (
                              <>
                                <Spinner color="white" size={"small"} />
                              </>
                            ) : (
                              <i className="fas fa-trash-alt"></i>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h3
                className="business-viewpanel-subtitle"
                style={{
                  textAlign: "center",
                }}
              >
                No hay cuentas conectadas
              </h3>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
}

export default connect(select)(BusinessIntegrations);
